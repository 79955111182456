<template>
  <el-dialog
    :visible.sync="show"
    :title="title"
    center
  >
    <div v-if="detail" class="dialog-wrapper">
      <div class="group">
        <div class="group-title">认证信息</div>
        <div class="info-item">
          <div class="info-item-label">军人姓名：</div>
          <div class="info-item-value">{{ detail.userName }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">身份证号：</div>
          <div class="info-item-value">{{ detail.idCard }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">联系人：</div>
          <div class="info-item-value">{{ detail.contactUser }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">联系电话：</div>
          <div class="info-item-value">{{ detail.contactPhone }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">年龄：</div>
          <div class="info-item-value">{{ detail.age }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">性别：</div>
          <div class="info-item-value">{{ detail.sexName }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">参军时间：</div>
          <div class="info-item-value">{{ detail.joinArmyTime }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">退伍时间：</div>
          <div class="info-item-value">{{ detail.leaveArmyTime }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">从属部队：</div>
          <div class="info-item-value">{{ detail.troopsName }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">军种：</div>
          <div class="info-item-value">{{ detail.soldierTypeName }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">军阶/军职：</div>
          <div class="info-item-value">{{ detail.soldierRank }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">荣誉：</div>
          <div class="info-item-value">{{ detail.honour }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">军队故事：</div>
          <div class="info-item-value">{{ detail.troopsTale }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">证明信息：</div>
          <div class="info-item-value">
            <div class="img-list">
              <el-image
                v-for="item in detail.projectFileList"
                :key="item.id"
                style="width: 70px; height: 70px"
                :src="item.fileUrl"
                :preview-src-list="projectFileList"
                :z-index="9999"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="examine-form">
        <div v-if="pageType === 'look'" class="group">
          <div class="group-title">审核信息</div>
          <div class="info-item">
            <div class="info-item-label">审核状态：</div>
            <div class="info-item-value">{{ detail.checkStatusName }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">审核人：</div>
            <div class="info-item-value">{{ detail.userName }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">审核时间：</div>
            <div class="info-item-value">{{ detail.updateTime }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">审核意见：</div>
            <div class="info-item-value">{{ detail.checkOpinion }}</div>
          </div>
        </div>
        <div v-else class="group">
          <div class="group-title">审核</div>
          <el-form :model="form" label-width="100px">
            <el-form-item label="审核：">
              <el-radio-group v-model="form.checkStatus">
                <el-radio :label="2">通过</el-radio>
                <el-radio :label="1">未通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="审核意见：">
              <el-input v-model="form.checkOpinion" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="show = false">取 消</el-button>
      <el-button v-if="pageType !== 'look'" type="primary" size="small" @click="submit">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getSoldierDetail, editSoldierDetail } from '../../../utils/api'
import '@/assets/style/common.less'
export default {
  data() {
    return {
      show: false,
      title: '审核',
      id: '',
      detail: null,
      form: {
        checkStatus: 2,
        checkOpinion: ''
      },
      pageType: ''
    }
  },
  computed: {
    projectFileList() {
      return this.detail.projectFileList.map(item => item.fileUrl)
    },
    certifyFileList() {
      return this.detail.certifyFileList.map(item => item.fileUrl)
    }
  },
  methods: {
    open(id, type = '') {
      this.show = true
      this.id = id
      this.getDetail()
      if (type === 'look') {
        this.pageType = 'look'
        this.title = '查看详情'
      } else {
        this.pageType = ''
        this.title = '审核'
      }
    },
    async getDetail() {
      const res = await getSoldierDetail(this.id)
      const { success, data } = res
      if (success) {
        this.detail = data
      }
    },
    async submit() {
      const opts = {
        ...this.detail,
        ...this.form
      }
      const res = await editSoldierDetail(opts)
      const { success } = res
      if (success) {
        this.$message.success('操作成功')
        this.show = false
        this.$emit('updateList')
      } else {
        this.$message.error('操作失败')
      }
    }
  }
}
</script>
<style lang="less">
  .examine-form {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 20px 0;
  }
</style>
