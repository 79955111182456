<template>
  <div>
    <el-card>
      <el-form
        ref="searchForm"
        :model="searchForm"
        :rules="rules"
        inline
        size="mini"
      >
        <div>
          <el-form-item label="审核状态:">
            <el-radio-group
              v-model="searchForm.checkStatus"
              size="small"
              prop="checkStatus"
            >
              <el-radio label="">全部</el-radio>
              <el-radio :label="0">待审核</el-radio>
              <el-radio :label="1">审核未通过</el-radio>
              <el-radio :label="2">审核通过</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="军人姓名:">
            <el-input v-model="searchForm.userName" prop="userName" />
          </el-form-item>
          <el-form-item label="认证编号:">
            <el-input v-model="searchForm.id" prop="id" />
          </el-form-item>
          <el-form-item label="部队名称:">
            <el-input v-model="searchForm.troopsName" prop="troopsName" />
          </el-form-item>
          <el-form-item label="军阶:">
            <el-input v-model="searchForm.soldierRank" prop="soldierRank" />
          </el-form-item>
          <el-form-item label="认证时间:" prop="daterange">
            <el-date-picker
              v-model="daterange"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" @click="onSearch"
              >查询</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="resetSearch('searchForm')"
              >重置</el-button
            >
          </el-form-item>
        </div>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="list"
        :header-cell-style="{ background: '#F7F7F7', color: '#1E1E1E' }"
      >
        <el-table-column type="index" width="55" label="序号" />
        <el-table-column label="认证编号" prop="id" />
        <el-table-column label="军人姓名" prop="userName" />
        <el-table-column label="部队名称" prop="troopsName" />
        <el-table-column label="军阶/军职" prop="soldierRank" />
        <el-table-column label="认证时间" prop="authTime" />
        <el-table-column label="联系人" prop="contactUser" />
        <el-table-column label="联系电话" prop="contactPhone" />
        <el-table-column label="认证状态" prop="checkStatusName" />
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-if="row.checkStatus === 2"
              type="text"
              @click="showDetail(row.id, 'look')"
              >查看</el-button
            >
            <el-button
              v-if="row.checkStatus === 0"
              type="text"
              @click="showDetail(row.id)"
              >审核</el-button
            >
            <el-button type="text" @click="remove(row.id)">删除</el-button>
            <!-- <el-button type="text" @click="remove(row.id)">删除记录</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :hide-on-single-page="true"
        :current-page="searchForm.current"
        :page-size="searchForm.size"
        :total="total"
        layout="total, prev, pager, next, jumper"
        style="padding: 20px; margin-bottom: 50px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <Identity-Detail-Dialog ref="identityDetailDialog" @updateList="onSearch" />
  </div>
</template>

<script>
import { getSoldierAuth, deleteSoliderAuth } from '../../utils/api'
import IdentityDetailDialog from './components/IdentityDetailDialog.vue'
import moment from 'moment'

export default {
  components: {
    IdentityDetailDialog
  },
  data() {
    return {
      CheckStatus: {
        0: '审核中',
        2: '审核通过',
        1: '审核未通过'
      },
      daterange: [],
      searchForm: {
        current: 1,
        size: 8,
        checkStatus: '',
        userName: '',
        id: '',
        troopsName: '',
        soldierRank: '',
        endDate: '',
        startDate: ''
      },
      rules: {},
      list: [],
      total: 0,
      pages: 0,
      dataListLoading: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async remove(id) {
      const confirm = await this.$confirm('是否确认删除?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch()
      if (confirm === 'confirm') {
        const res = await deleteSoliderAuth(id)
        if (res.success) {
          this.$message.success('删除成功')
          this.getList()
        }
      } else {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      }
    },
    showDetail(id, type = '') {
      this.$refs.identityDetailDialog.open(id, type)
    },
    handleSizeChange(val) {
      this.searchForm.size = val
      this.searchForm.current = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.searchForm.current = val
      this.getList()
    },
    async getList() {
      this.dataListLoading = true
      if (this.daterange.length) {
        this.searchForm.startDate = moment(this.daterange[0]).format(
          'YYYY-MM-DD HH:mm:ss'
        )
        this.searchForm.endDate = moment(this.daterange[1]).format(
          'YYYY-MM-DD HH:mm:ss'
        )
      } else {
        this.searchForm.startDate = ''
        this.searchForm.endDate = ''
      }
      const res = await getSoldierAuth({ ...this.searchForm })
      this.dataListLoading = false
      const { success, data } = res
      if (success) {
        this.list = data.records
        this.total = data.total
      }
    },
    onSearch() {
      this.searchForm.current = 1
      this.getList()
    },
    resetSearch(formName) {
      this.searchForm.checkStatus = ''
      this.daterange = []
      this.searchForm.id = ''
      this.searchForm.userName = ''
      this.searchForm.troopsName = ''
      this.searchForm.soldierRank = ''
      this.$refs[formName].resetFields()
      this.searchForm.current = 1
      this.getList()
    }
  }
}
</script>

<style>
</style>
